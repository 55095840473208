exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accommodations-js": () => import("./../../../src/pages/accommodations.js" /* webpackChunkName: "component---src-pages-accommodations-js" */),
  "component---src-pages-bride-and-groom-js": () => import("./../../../src/pages/bride-and-groom.js" /* webpackChunkName: "component---src-pages-bride-and-groom-js" */),
  "component---src-pages-ceremony-and-reception-js": () => import("./../../../src/pages/ceremony-and-reception.js" /* webpackChunkName: "component---src-pages-ceremony-and-reception-js" */),
  "component---src-pages-example-js": () => import("./../../../src/pages/example.js" /* webpackChunkName: "component---src-pages-example-js" */),
  "component---src-pages-for-travelers-js": () => import("./../../../src/pages/for-travelers.js" /* webpackChunkName: "component---src-pages-for-travelers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-registry-alternatives-js": () => import("./../../../src/pages/registry-alternatives.js" /* webpackChunkName: "component---src-pages-registry-alternatives-js" */),
  "component---src-pages-registry-js": () => import("./../../../src/pages/registry.js" /* webpackChunkName: "component---src-pages-registry-js" */)
}

